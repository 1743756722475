<template>
  <section>
    <b-loading :active="loadingProperties" :is-full-page="false"></b-loading>

    <b-modal
      :active.sync="showFilterModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <TableColumnFilter
          :columns="visibleFiltersModalColumn"
          :data="availableFilters"
          :draggable="false"
          @toggleVisibility="onFiltersToggleVisibility"
          @toggleVisibilityAll="onFiltersToggleVisibility"
        >
          <template #header>
            <p class="modal-card-title is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.header")
                  : $t("table.col-options.header")
              }}
            </p>
          </template>
          <template #beforeTable>
            <p class="is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.col-filter-title")
                  : $t("table.col-options.col-filter-title")
              }}
            </p>
          </template>
        </TableColumnFilter>
      </template>
    </b-modal>

    <!-- property filters -->
    <section class="section has-background-eurotext-light">
      <div class="container">
        <button class="button is-primary block" @click="showFilterModal = true">
          {{ $t("buttons.hide_search_filters") }}
        </button>

        <form
          @submit.prevent="fetchDocuments"
          @reset.prevent="resetForm"
          class="search-filters block"
        >
          <div class="columns is-multiline">
            <div
              class="column is-one-third-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd custom-column-filter-align"
            >
              <b-field>
                <template #label
                  ><span class="has-text-light">{{
                    $t(`concatOperator`)
                  }}</span></template
                >
                <b-select
                  placeholder="Select an operator"
                  v-model="selectedConcatOperator"
                  expanded
                >
                  <option v-for="op in concatOperators" :value="op" :key="op">
                    {{ op }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div
              v-for="(filter, index) in visibleFilters"
              :key="`${filter.name}_${index}`"
              class="column is-one-third-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd custom-column-filter-align search-filter"
            >
              <b-field>
                <template #label
                  ><span class="has-text-light">{{
                    $t(`docclass-properties.${filter.description}`)
                  }}</span></template
                >

                <!-- If filter is class id, lock the values -->
                <template v-if="filter.name === 'class_id'">
                  <b-select v-model="filter.operator" disabled>
                    <option
                      v-for="option in valueTypes[filter.type].operators"
                      :value="option.key"
                      :key="option.key"
                      v-html="option.icon"
                    ></option>
                  </b-select>

                  <b-input :value="className" expanded disabled></b-input>
                </template>

                <template v-else>
                  <b-select v-model="filter.operator">
                    <option
                      v-for="option in getFilterOperator(filter)"
                      :value="option.key"
                      :key="option.key"
                      v-html="option.icon"
                    ></option>
                  </b-select>

                  <!-- If filter is stato conservazione, use a select with all status values -->
                  <!-- <b-select
                    v-if="filter.name === 'stato_conservazione'"
                    v-model="filter.value"
                    expanded
                  >
                    <option :value="null"></option>
                    <option
                      v-for="status in docStatusList"
                      :value="status"
                      :key="status"
                    >
                      {{ status }}
                    </option>
                  </b-select> -->

                  <b-dropdown
                    v-if="filter.name === 'stato_conservazione'"
                    v-model="filter.value"
                    multiple
                    aria-role="list"
                    expanded
                  >
                    <template #trigger="{ active }">
                      <button
                        type="button"
                        class="button arrow-right is-fullwidth"
                      >
                        <span>{{
                          $t("document.status.select_label", {
                            number: filter.value ? filter.value.length : "0",
                          })
                        }}</span>
                        <b-icon
                          :icon="active ? 'menu-up' : 'menu-down'"
                        ></b-icon>
                      </button>
                    </template>

                    <b-dropdown-item
                      aria-role="listitem"
                      v-for="status in docStatusList"
                      :value="status"
                      :key="status"
                    >
                      <span>{{ $t(`document.status.${status}`) }}</span>
                    </b-dropdown-item>
                  </b-dropdown>

                  <custom-b-date-picker
                    v-else-if="isDate(filter.type) || isDateTime(filter.type)"
                    icon="calendar-today"
                    :range="filter.operator === operators.BETWEEN.key"
                    expanded
                    v-model="filter.value"
                    :locale="$i18n.locale"
                    :dateFormat="$store.getters.getDatePattern"
                  >
                  </custom-b-date-picker>

                  <boolean-input
                    v-else-if="isBoolean(filter.type)"
                    expanded
                    v-model="filter.value"
                    style="width: 100%"
                  >
                  </boolean-input>

                  <!-- <b-select
                    v-else-if="isBoolean(filter.type)"
                    v-model="filter.value"
                    expanded
                  >
                    <slot />
                  </b-select> -->

                  <b-taginput
                    v-else-if="filter.operator === operators.IN.key"
                    v-model="filter.value"
                    :confirm-keys="[';', 'Enter', 'Tab']"
                    :on-paste-separators="[';']"
                    :maxtags="$config.search.max_search_tags"
                    has-counter
                    expanded
                    check-infinite-scroll
                    ellipsis
                    aria-close-label="Delete this tag"
                    class="has-text-light"
                  ></b-taginput>

                  <b-input v-else v-model="filter.value" expanded></b-input>
                </template>
              </b-field>
            </div>
          </div>

          <div class="buttons">
            <b-button
              type="is-primary"
              native-type="submit"
              icon-left="magnify"
            >
              {{ $t("buttons.search") }}
            </b-button>
            <b-button type="is-primary" native-type="reset" icon-left="eraser">
              {{ $t("buttons.erase") }}
            </b-button>
          </div>
        </form>
      </div>
    </section>

    <section class="section" v-if="tableColumns.length">
      <document-table
        :data="tableData"
        :tableColumns="tableColumns"
        :isLoadingRows="loadingDocuments"
        :total="totalTablePages"
        :perPage="tablePerPage"
        :totalRecords="totalDocumentNum"
        :downloadTypes="downloadTypes"
        :saveState="true"
        :stateKey="stateKey"
        :defaultOrderedColumnsNames="[]"
        :showEmitStateCheckbox="true"
        :backend-sorting="true"
        @page="onTablePage($event)"
        @row-click="openDetail"
        @download="download"
        @download-total="downloadTotal"
        @column-state-close="onTableColumnsStateClose"
        @sort-data="onTableSort"
      >
        <template #cell="{ cellData, column }">
          <span
            v-if="cellData && column.field === 'stato_conservazione'"
            class=""
            >{{ $t(`document.status.${cellData}`) }}</span
          >
          <template v-else>{{ cellData }}</template>
        </template>
      </document-table>
    </section>
  </section>
</template>

<script>
import { commonMixin, searchPageMixin, uaMixin } from "@/mixins";
import { classService, searchService } from "@/services";
import {
  Operators,
  KValueTypes,
  getDocStatus,
  ConcatOperators,
  checkType,
} from "@/helpers/constants";
import {
  KUrls,
  KDocumentDownloadType,
  getDocumentDownloadType,
} from "@/helpers/constants";
import CustomBDatePicker from "@/components/inputs/CustomBDatePicker.vue";
import BooleanInput from "@/components/inputs/BooleanInput.vue";
import DocumentTable from "@/components/DocumentTable/DocumentTable";
import TableColumnFilter from "@/components/DocumentTable/TableColumnFilter";
import * as dateUtils from "@/helpers/date";
import { mapGetters } from "vuex";
import { downloadService } from "@/services";
import { KFilterSpecificOperators } from "../helpers/constants";

const DEFAULT_OPERATOR = "E";

export default {
  components: {
    CustomBDatePicker,
    BooleanInput,
    DocumentTable,
    TableColumnFilter,
  },
  mixins: [commonMixin, searchPageMixin, uaMixin],
  data() {
    return {
      loadingProperties: false,
      loadingDocuments: false,
      filters: [],
      documentData: [],
      valueTypes: KValueTypes,
      operators: Operators,
      concatOperators: [...ConcatOperators],
      selectedConcatOperator: ConcatOperators[0],
      docStatusList: [],
      downloadTypes: getDocumentDownloadType(),
      filterClassListInput: "",
    };
  },
  computed: {
    ...mapGetters("menu", ["getCompanyClasses", "getClassNameById"]),
    classId: function () {
      return Number(this.$route.query.class);
    },
    classList() {
      return this.getCompanyClasses(this.companyName);
    },
    classListFiltered() {
      return this.classList.filter(
        (item) =>
          item.name
            .toLowerCase()
            .indexOf(this.filterClassListInput.toLowerCase()) >= 0
      );
    },
    isHistory: function () {
      if (this.$route.path.includes(KUrls.routes.SEARCH)) {
        return true;
      } else {
        return false;
      }
    },
    isLoading: function () {
      return this.loadingProperties || this.loadingDocuments;
    },
    stateKey() {
      const view = this.$route.path.split("/").pop();
      return `${this.companyName}.${this.className}.${view}`;
    },
    tableColumns() {
      return this.filters
        .filter((f) => f.name !== "class_id")
        .map((f) => {
          return {
            field: f.name,
            label: this.$t(`docclass-properties.${f.description}`),
          };
        });
    },
    tableData() {
      // return this.documentData.map((doc) => {
      //   return doc["properties"];
      // });
      return Array.from(this.documentData, (doc) => doc["properties"]);
    },
    availableFilters() {
      return this.filters.filter((f) => f.name !== "class_id");
    },
    // override the mixin computed prop
    visibleFilters() {
      return this.filters.filter((f) => f.visible && f.name !== "class_id");
    },
  },
  methods: {
    download(payload) {
      const artifacts = payload.artifacts;
      const documents = payload.selected;
      const ids = [];
      for (const doc of documents) {
        ids.push(doc.id);
      }
      let metadataList = [];
      if (
        (artifacts & KDocumentDownloadType.METADATA) ===
        KDocumentDownloadType.METADATA
      ) {
        metadataList = payload.metadata;
      }
      this.isDownloading = true;
      downloadService
        .downloadDocuments(
          this.companyName,
          this.$i18n.locale,
          "ddMMyyyy",
          true,
          ids,
          artifacts,
          metadataList
        )
        .then((data) => {
          console.log(data);
        })
        .finally(() => (this.isDownloading = false));
    },
    downloadTotal(payload) {
      let classIdFilter = this.filters.find((f) => f.name === "class_id");
      if (!classIdFilter.value) {
        classIdFilter.value = [this.classId];
        classIdFilter.operator = Operators.IN.key;
      }

      const artifacts = payload.artifacts;
      this.isDownloading = true;
      const docFilters = this.filters.filter((f) => {
        if (f.value) {
          if (Array.isArray(f.value)) {
            if (f.value.length > 0) {
              // Array not empty
              return true;
            } else {
              // Array empty
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      });

      const queryFilterGroup = {
        operator: this.selectedConcatOperator,
        filters: docFilters,
      };

      let metadataList = [];
      if (
        (artifacts & KDocumentDownloadType.METADATA) ===
        KDocumentDownloadType.METADATA
      ) {
        metadataList = payload.metadata;
      }
      downloadService
        .downloadAllDocuments(
          this.companyName,
          this.className,
          queryFilterGroup,
          this.$i18n.locale,
          "ddMMyyyy",
          true,
          artifacts,
          metadataList,
          this.isHistory,
          !this.isHistory
        )
        .then((data) => {
          console.log(data);
        })
        .finally(() => (this.isDownloading = false));
    },
    /**
     * Get documents from web service
     */
    fetchDocuments() {
      let classIdFilter = this.filters.find((f) => f.name === "class_id");
      if (!classIdFilter.value) {
        classIdFilter.value = [this.classId];
        classIdFilter.operator = Operators.IN.key;
      }

      const docFilters = this.filters.filter((f) => {
        if (f.value || f.value === false) {
          if (Array.isArray(f.value)) {
            if (f.value.length > 0) {
              // Array not empty
              return true;
            } else {
              // Array empty
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      });

      const queryFilterGroup = {
        operator: this.selectedConcatOperator,
        filters: docFilters,
      };

      const tableSorting = {};
      this.tableSortingPriority.forEach((el) => {
        tableSorting[el.field] = el.order;
      });

      this.loadingDocuments = true;

      const { companyName, tablePage, tablePerPage } = this;
      const historyDocs = this.isHistory;

      searchService
        .searchDocuments(
          companyName,
          queryFilterGroup,
          tablePage,
          tablePerPage,
          !historyDocs,
          historyDocs,
          tableSorting
        )
        .then((res) => {
          this.documentData = res["documents"];
          this.tablePage = res["page"];
          this.totalTablePages = res["total_pages"];
          this.totalDocumentNum = res["total_results"];
        })
        .finally(() => (this.loadingDocuments = false));
    },
    /**
     * Get filters from web service
     */
    async fetchFilters() {
      this.loadingProperties = true;
      try {
        const filters = await classService.fetchDocumentProperties(
          this.companyName,
          this.classId,
          true,
          false
        );
        const newFilters = filters.map((f) => {
          let defaultOperator = KFilterSpecificOperators[f.name]
            ? KFilterSpecificOperators[f.name].defaultOperator.key
            : DEFAULT_OPERATOR;
          const o = {
            value: null,
            operator: defaultOperator,
            visible: true,
            label: this.$t(`docclass-properties.${f.description}`),
            ...f,
          };
          if (f.name === "class_id") {
            console.log("class_id", this.classId);
            o.value = [this.classId];
          } else if (f.name === "this_year") {
            if (this.$config.search.auto_year_filter)
              o.value = new Date().getUTCFullYear();
          }
          return o;
        });
        this.filters = newFilters;
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingProperties = false;
      }
    },
    isDocHistory(docId) {
      const found = this.documentData.find((data) => {
        return docId === data.properties.id;
      });
      if (found) {
        return found.history;
      }
      return undefined;
    },
    parseDate(date) {
      return dateUtils.parseDate(date, "");
    },
    openDetail(payload) {
      const docId = payload.id;
      const classId = this.classId;
      const hisotry = this.isHistory;
      const tab = {
        id: docId,
        component: "DocumentDetail",
        label: `Doc ${docId}`,
        icon: this.$config.icons.tabs.document_detail,
        props: {
          classId,
          companySchema: this.companyName,
          documentId: docId,
          properties: [],
          isHistory: hisotry,
          stateKey: this.stateKey,
        },
      };
      this.$store.dispatch("tabs/openTab", tab);
    },
    resetForm: function () {
      console.log("resetting");
      for (const prop of this.filters) {
        if (prop.name === "class_id") {
          prop.value = [this.classId];
          prop.operator = Operators.IN.key;
        } else {
          prop.value = null;
        }
      }
    },
    ...checkType,
  },
  mounted() {
    this.docStatusList = getDocStatus(this.isHistory);
    this.fetchFilters().then(() => this.loadFiltersState());
  },
};
</script>

<style></style>
